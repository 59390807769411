import { graphql } from "gatsby";
import React, { useState } from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import {
  DownloadIcon,
  CancelIcon,
  ArrowRightShortIcon,
} from "../../images/Icons";
import Breadcrumb from "../../components/breadcrumb";

import Payments1 from "../../images/press/product-images/payments-1.png";
import Payments2 from "../../images/press/product-images/payments-2.png";
import Payments3 from "../../images/press/product-images/payments-3.png";
import Payments4 from "../../images/press/product-images/payments-4.png";
import Credit1 from "../../images/press/product-images/credit-1.png";
import Credit2 from "../../images/press/product-images/credit-2.png";
import Credit3 from "../../images/press/product-images/credit-3.png";
import Credit4 from "../../images/press/product-images/credit-4.png";
import Banking1 from "../../images/press/product-images/banking-1.png";
import Banking2 from "../../images/press/product-images/banking-2.png";
import Banking3 from "../../images/press/product-images/banking-3.png";
import Banking4 from "../../images/press/product-images/banking-4.png";
import BM1 from "../../images/press/product-images/bm-1.png";
import BM2 from "../../images/press/product-images/bm-2.png";
import BM3 from "../../images/press/product-images/bm-3.png";
import BM4 from "../../images/press/product-images/bm-4.png";

import { Modal } from "react-bootstrap";

const ProductImagesPage = () => {
  const [img, setImg] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showImage = (img) => {
    setImg(img);
    handleShow();
  };

  return (
    <Layout pageTitle="Product Images - Moniepoint Inc.">
      <ProductImagesContainer>
        <div className="content">
          <Breadcrumb
            title="Press"
            link="/press"
            current="presspage.product_images"
          />
          <div className="top-div">
            <h2 className="title">
              <Trans>presspage.product_images</Trans>
            </h2>
          </div>

          <div className="image-section">
            <div className="section-header">
              <h3 className="title">
                <Trans>payments</Trans>
              </h3>
              <a download="Payments.zip" href="https://drive.google.com/uc?export=download&id=1zOmsmAnjg0P8W7PfukzOHZzrrR1Cvx4i" className="btn download-btn">
                <Trans>download_all</Trans>
                <DownloadIcon />
              </a>
            </div>
            <div className="section-body">
              <div
                className="image-container"
                onClick={() => showImage(Payments1, "payment-1.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/payments-1.png"
                  className="product-image grow"
                />
                <a
                  href={Payments1}
                  download="payment-1.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div
                className="image-container"
                onClick={() => showImage(Payments2, "payment-2.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/payments-2.png"
                  className="product-image grow"
                />
                <a
                  href={Payments2}
                  download="payment-2.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div
                className="image-container"
                onClick={() => showImage(Payments3, "payment-3.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/payments-3.png"
                  className="product-image grow"
                />
                <a
                  href={Payments3}
                  download="payment-3.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div
                className="image-container"
                onClick={() => showImage(Payments4, "payment-4.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/payments-4.png"
                  className="product-image grow"
                />
                <a
                  href={Payments4}
                  download="payment-4.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
          </div>
          <div className="image-section">
            <div className="section-header">
              <h3 className="title">
                <Trans>credit</Trans>
              </h3>
              <a download="Credit.zip" href="https://drive.google.com/uc?export=download&id=1oFoLm1_wBRgA4MNGC8Lw-Q3S367To1Ap" className="btn download-btn">
                <Trans>download_all</Trans>
                <DownloadIcon />
              </a>
            </div>
            <div className="section-body"
            >
              <div className="image-container"
                onClick={() => showImage(Credit1, "credit-1.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/credit-1.png"
                  className="product-image grow"
                />
                <a
                  href={Credit1}
                  download="credit-1.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(Credit2, "credit-2.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/credit-2.png"
                  className="product-image grow"
                />
                <a
                  href={Credit2}
                  download="credit-2.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(Credit3, "credit-3.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/credit-3.png"
                  className="product-image grow"
                />
                <a
                  href={Credit3}
                  download="credit-3.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(Credit4, "credit-4.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/credit-4.png"
                  className="product-image grow"
                />
                <a
                  href={Credit4}
                  download="credit-4.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
          </div>
          <div className="image-section">
            <div className="section-header">
              <h3 className="title">
                <Trans>business_management</Trans>
              </h3>
              <a download="Business-Management.zip" href="https://drive.google.com/uc?export=download&id=1edsfZajoDsVD9KwzAS1ND9Ooo90lzRnO" className="btn download-btn">
                <Trans>download_all</Trans>
                <DownloadIcon />
              </a>
            </div>
            <div className="section-body">
              <div className="image-container"
                onClick={() => showImage(BM1, "business-management-1.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/bm-1.png"
                  className="product-image grow"
                />
                <a
                  href={BM1}
                  download="business-management-1.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(BM2, "business-management-2.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/bm-2.png"
                  className="product-image grow"
                />
                <a
                  href={BM2}
                  download="business-management-2.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(BM3, "business-management-3.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/bm-3.png"
                  className="product-image grow"
                />
                <a
                  href={BM3}
                  download="business-management-3.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(BM4, "business-management-4.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/bm-4.png"
                  className="product-image grow"
                />
                <a
                  href={BM4}
                  download="business-management-4.png"
                  to=""
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
          </div>
          <div className="image-section">
            <div className="section-header">
              <h3 className="title">
                <Trans>banking</Trans>
              </h3>
              <a download="Banking.zip" href="https://drive.google.com/uc?export=download&id=1JkO1OHr_nA74NmLU2G-UMyKIfGHHobj-" className="btn download-btn">
                <Trans>download_all</Trans>
                <DownloadIcon />
              </a>
            </div>
            <div className="section-body">
              <div className="image-container"
                onClick={() => showImage(Banking1, "banking-1.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/banking-1.png"
                  className="product-image grow"
                />
                <a
                  href={Banking1}
                  download="banking-1.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(Banking2, "banking-2.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/banking-2.png"
                  className="product-image grow"
                />
                <a
                  href={Banking2}
                  download="banking-2.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(Banking3, "banking-3.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/banking-3.png"
                  className="product-image grow"
                />
                <a
                  href={Banking3}
                  download="banking-3.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
              <div className="image-container"
                onClick={() => showImage(Banking4, "banking-4.png")}
              >
                <StaticImage
                  alt="..."
                  src="../../images/press/product-images/banking-4.png"
                  className="product-image grow"
                />
                <a
                  href={Banking4}
                  download="banking-4.png"
                  className="btn download-link"
                >
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </ProductImagesContainer>
      <Modal
        show={show}
        onHide={handleClose}
        dialogAs={PopOutDialog}
        // backdrop="static"
        centered
        animation={false}
        keyboard={false}
      >
        <Modal.Body>
          <div className="close-button" onClick={handleClose}>
            <ArrowRightShortIcon color="white" size={19} />
          </div>
          <div className="container">
            <img src={img} style={{ width: "100%" }} />
            <div className="action-container">
              <button
                onClick={handleClose}
                download="banking-4.png"
                className="btn download-btn"
              >
                <span>
                  <Trans>Close</Trans>
                </span>
                <CancelIcon size={16} />
              </button>
              <a
                href={img}
                download="banking-4.png"
                className="btn btn-primary"
              >
                <span>
                  <Trans>presspage.download_image</Trans>
                </span>
                <DownloadIcon size={16} />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default ProductImagesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ProductImagesContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 0 150px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 0 60px 150px;
    }

    .top-div {
      margin-top: 48px
      margin-bottom: 16px;

      .title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 57px;
        max-width: 851px;
      }
    }
    .bottom-div {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .text-content {
        font-size: 20px;
        font-weight: 400;
        line-height: 32.2px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 586px;
        margin-bottom: 0;
      }
    }

    .image-section {
      margin-top: 48px;
      .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 48px;

        .title {
          font-family: "Founders Grotesk";
          font-size: 36px;
          font-weight: 500;
          line-height: 42px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
      .section-body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 49px;
        padding-bottom: 20px;

        .image-container {
          max-width: 248px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          cursor: pointer;

          .product-image {
            width: 100%;
            height: auto;
            border-radius: 8px;
            margin-bottom: 12px;
          }

          .btn.download-link {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 0 20px 64px;

      .top-div {
        margin-bottom: 16px;
        .title {
          font-size: 32px;
          line-height: 37.76px;
        }
      }
      .bottom-div {
        flex-direction: column;
        align-items: flex-start;
        .text-content {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 16px;
        }
      }

      .image-section {
        margin-top:24px;
        .section-header {
          margin-bottom: 32px;
          .title {
            font-size: 24px;
            line-height: 28px;
          }
          .button {
            padding: 5.5px 16px;
          }
        }
        .section-body {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 16px;
        }
      }
    }
  }
`;

const PopOutDialog = styled.div`
  max-width: 1090px !important;
  width: 100%;
  margin: auto;
  height: 100vh;
  display: flex;

  .modal-body {
    .close-button {
      display: none;
    }
    .container {
      width: 100%;
      padding: 48px;
      border-radius: 16px;

      background-color: white;
      img {
        border-radius: 16px;
        margin-bottom: 48px;
        height: 616px;
        object-fit: contain;
      }

      .action-container {
        display: flex;
        justify-content: center;
        gap: 24px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .modal-body {
      padding: 0;
      height: 100vh;
      border-radius: 0px;
      background-color: rgba(37, 44, 50, 1);
      position: relative;

      .container {
        background: transparent;

        .action-container {
          button.btn.download-btn {
            display: none;
          }
          .btn.btn-primary {
            width: 225px;
          }
        }
      }

      .close-button {
        height: 38px;
        width: 38px;
        border-radius: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background: rgba(255, 255, 255, 0.13);
        top: 80px;
        left: 20px;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`;
